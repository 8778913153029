import React, { useState } from 'react';
import {
  IonContent,
  IonPage,
  IonInput,
  IonItem,
  IonButton,
  IonLabel,
  IonText,
  IonSpinner
} from '@ionic/react';
import './Home.css';

type Errors = {
  [key: number]: string[];
};

const errors: Errors = {
  0: ['Network error.', 'ネットワークエラー。', 'Error de conexión.'],
  403: ['Invalid password.', '無効なパスワード。', 'Contraseña incorrecta.'],
  500: ['Server error.', 'サーバーエラー。', 'Error del servidor.']
};

const Home: React.FC = () => {
  const [password, setPassword] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string[]>([]);

  const handleChange = (event: CustomEvent) => {
    setPassword(event.detail.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(process.env.REACT_APP_CHECK_PASSWORD_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ password })
      });

      if (!response.ok) {
        return showError(response.status);
      }

      const { url } = await response.json();

      if (!url) {
        return showError(404);
      }

      window.location = url;
    } catch (error) {
      console.error(error);
      showError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const showError = (status: number = 0) => {
    setError(errors[status] || errors[500]);
  };

  return (
    <IonPage>
      <IonContent>
        <div className="container">
          <h1>Rodrigo & Nami</h1>
          <div className="info">
            <p>Please type the password to access.</p>
            <p>アクセスするにはパスワードを入力してください。</p>
            <p>Por favor escribe la contraseña para acceder.</p>
          </div>
          <div className="error">
            {error.map(line => (
              <p key={line}>
                <IonText color="danger">{line}</IonText>
              </p>
            ))}
          </div>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <IonItem>
                <IonLabel position="floating">
                  Password / パスワード / Contraseña
                </IonLabel>
                <IonInput
                  name="password"
                  autofocus
                  clearInput
                  onIonChange={handleChange}
                />
              </IonItem>
              <IonButton type="submit" disabled={loading}>
                {loading ? <IonSpinner /> : 'Send / 送る / Enviar'}
              </IonButton>
            </form>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
